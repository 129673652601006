<template>
	<h1>Home dashboard</h1>
	<h2>Welcome {{ name }}!</h2>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	mounted() {
		if (this.activeUser) {
			this.name = this.activeUser.name;
		}
	},

	data() {
		return {
			name: "",
		};
	},
	name: "HomeDashboard",

	props: [],

	computed: {
		...mapGetters(["activeUser"]),
	},
};
</script>
<style></style>
